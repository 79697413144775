html,
body {
  overflow-x: hidden;
}
#root {
  height: 100%;
}

h3 {
  display: flex;
  align-items: center;
}

a:-webkit-any-link,
a:-webkit-any-link {
  color: "#57a1b9" !important;
}

a:any-link,
a:-webkit-any-link {
  color: "#57a1b9" !important;
}

code {
  display: inline-block;
  background-color: #eaeaea;
  color: #555555;
  font-family: "Source Code Pro", monospace;
  border-radius: 6px;
  padding: 6px;
  font-size: 12px;
}

